import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: auto;
	}
`
const Root = styled(Grid, { shouldForwardProp })`
	align-items: ${({ verticalAlign }) => verticalAlign};
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	position: relative;
	${OverlayContainer} {
		text-align: center;
		${({ theme }) => theme.breakpoints.up('sm')} {
			text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
		}
	}

	&.bottom {
		align-items: flex-end;
		bottom: 0;
	}

	&.top {
		align-items: flex-start;
		bottom: auto;
	}

	&.center {
		align-items: center;
		bottom: auto;
	}

	&.title-alternative {
		.title {
			font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
		}
	}
`

export { Root, OverlayContainer }
